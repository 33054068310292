import Randomizer from './Randomizer';

export default class CodenameList {
    private _codeNames: string[] = [
        'AGILEVIEW',
        'AGILITY',
        'AIRGAP',
        'AIRSTEED',
        'ALPHA',
        'ALTEREGO',
        'AMBULANT',
        'Anchor',
        'ANCHORY',
        'ANGRYNEIGHBOR',
        'APERIODIC',
        'AQUADOR',
        'ARGON',
        'ARKSTREAM',
        'ARTEMIS',
        'ASSOCIATION',
        'AUNTIE',
        'AUTOSOURCE',
        'BACONRIDGE',
        'BANANAGLEE',
        'BANYAN',
        'BEACHHEAD',
        'BELLS',
        'BELLTOPPER',
        'BELLVIEW',
        'BINOCULAR',
        'BLACKFOOT',
        'BLACKHEART',
        'BLACKMAGIC',
        'BLACKPEARL',
        'BLACKWATCH',
        'BLARNEY',
        'BLINDDATE',
        'BLUEANCHOR',
        'BLUEZEPHYR',
        'BOUNDLESSINFORMANT',
        'BROKER',
        'BROTH',
        'BSR',
        'BULLDOZER',
        'BULLRUN',
        'BULLSEYE',
        'Byzantine',
        'CADENCE',
        'Candor',
        'CANDYGRAM',
        'CANNON',
        'CARBOY',
        'CARILLION',
        'CASPORT',
        'CDR',
        'CDRDiode',
        'CENTERMASS',
        'CHALKFUN',
        'CHASEFALCON',
        'CHEWSTICK',
        'CHIMNEYPOOL',
        'CHIPPEWA',
        'CIMBRICINEPLEX',
        'CLOUD',
        'COASTLINE',
        'COBALTFALCON',
        'COMMONDEER',
        'CONJECTURE',
        'CONOP',
        'CONTRAOCTAVE',
        'CONVEYANCE',
        'CORE',
        'COTS',
        'COTTONMOUTH-1',
        'COTTONMOUTH-II',
        'COTTONMOUTH-III',
        'COURIERSKILL',
        'Covert',
        'CRADLE',
        'CREDIBLE',
        'CREST',
        'CRISSCROSS',
        'CROSSBEAM',
        'CRUMPET',
        'CRYPTO',
        'CRYPTOENABLED',
        'CTX4000',
        'CUSTOMS',
        'CW',
        'CYCLONE',
        'DANCINGOASIS',
        'DANDERSPRIT',
        'DANDERSPRITZ',
        'DANGERMOUSE',
        'DARKTHUNDER',
        'DECKPIN',
        'DELTA',
        'DEWSWEEPER',
        'DIETYBOUNCE',
        'DIKTER',
        'Diode',
        'DIODE',
        'DISHFIRE',
        'DOCKETDICTATE',
        'DOGCOLLAR',
        'DRAGONFLY',
        'DROPMIRE',
        'DROPOUTJEEP',
        'DRTBOX',
        'DRUID',
        'DYNAMO',
        'EBSR',
        'EGOTISTICALGIRAFFE',
        'EGOTISTICALGOAT',
        'ENABLED',
        'ENDUE',
        'ENTOURAGE',
        'EPICFAIL',
        'ERRONEOUSINGENUITY',
        'EVENINGEASEL',
        'EVILOLIVE',
        'EWALK',
        'EXPECTATIONS',
        'FACELIFT',
        'FAIRVIEW',
        'FALLOUT',
        'FASCIA',
        'FEEDTROUGH',
        'FERRETCANNON',
        'FET',
        'FINKDIFFERENT',
        'FIREWALK',
        'FISHBOWL',
        'FLUXBABBIT',
        'FLYING',
        'FORK',
        'FOXACID',
        'FOXSEARCH',
        'FOXTRAIL',
        'FREEFLOW',
        'FREEZEPOST',
        'FRIEZERAMP',
        'FUNNELOUT',
        'GALAXY',
        'GAMUT',
        'GECKO',
        'GENESIS',
        'GENIE',
        'GENTE',
        'GEOFUSION',
        'GHOSTMACHINE',
        'GINSU',
        'GLOBAL',
        'GODSURGE',
        'GOPHERSET',
        'GOURMETTROUGH',
        'GREAT',
        'Hades',
        'HALLUXWATER',
        'HAMMERMILL',
        'HC12',
        'HEADWATER',
        'HERCULES',
        'HIGHLANDS',
        'HIGHTIDE',
        'HOLLOWPOINT',
        'HOMEBASE',
        'HOWLERMONKEY',
        'HUSH',
        'Hx9',
        'II',
        'Insertion',
        'INTELINK',
        'INTRUDER',
        'IRATEMONK',
        'IRONCHEF',
        'ISHTAR',
        'ISLANDTRANSPORT',
        'ITETAMALE',
        'IVY',
        'JADE',
        'JETPLOW',
        'JUGGERNAUT',
        'JUNIORMINT',
        'KEYHOLE',
        'KLONDIKE',
        'KONGUR',
        'LANDSHARK',
        'LANTERN',
        'LEGION',
        'LFS-2',
        'LHR',
        'LIFESAVER',
        'LIGHT',
        'LITHIUM',
        'LONGHAUL',
        'LOPERS',
        'LOUDAUTO',
        'LP',
        'MADCAPOCELOT',
        'MAESTRO',
        'MAGIC',
        'MAGNETIC',
        'MAILORDER',
        'MAIN',
        'MAINWAY',
        'MARINA',
        'MAUI',
        'MCM',
        'MESSIAH',
        'METTLESOME',
        'MIDDLEMAN',
        'MINERALIZE',
        'MJOLNIR',
        'MOCCASIN',
        'MONKEYCALENDAR',
        'MONKEYROCKET',
        'MOONLIGHTPATH',
        'MORAY',
        'MULLENIZE',
        'MUSCULAR',
        'MUTANT',
        'NEBULA',
        'network',
        'NEWTONS',
        'NIGHTSTAND',
        'NIGHTWATCH',
        'NUCLEON',
        'OAKSTAR',
        'OCEAN',
        'OCEANARIUM',
        'OCELOT',
        'OCONUS',
        'OCTAVE',
        'OCTSKYWARD',
        'OILSTOCK',
        'OLYMPUS',
        'OLYMPUSFIRE',
        'OMNIGAT',
        'ONEROOF',
        'ONIONBREATH',
        'ORANGEBLOSSOM',
        'ORANGECRUSH',
        'OSCAR',
        'OSMJCM-II',
        'PAINTEDEAGLE',
        'PARCHDUSK',
        'PATHFINDER',
        'PAWLEYS',
        'PENDLETON',
        'PHOTOANGLO',
        'PICARESQUE',
        'PICASSO',
        'PIEDMONT.',
        'PIG',
        'PINWALE',
        'PITCHFORD',
        'PLUS',
        'PPM',
        'PRISM',
        'PROTON',
        'PROTOSS',
        'PSP',
        'PUPPY',
        'PUZZLECUBE',
        'QFD',
        'QFIRE',
        'QIM/JMSQ',
        'QUANTUM',
        'QUICK',
        'QUICKANT',
        'RADON',
        'RAGEMASTER',
        'RAGTIME',
        'RAMPART/RAMPART-T',
        'reflector',
        'REMATION',
        'Retro',
        'RETURNSPRING',
        'ROC',
        'ROCKYKNOB',
        'RONIN',
        'RUBY',
        'RUFF',
        'SABRE',
        'SCHOOLMONTANA',
        'SDR',
        'SEAGULLFARO',
        'SEASONEDMOTH',
        'SEMESTER',
        'SENTINEL',
        'SERUM',
        'SETTEE',
        'SHARKFIN',
        'SHARPFOCUS',
        'SHELLTRUMPET',
        'SHIFTINGSHADOW',
        'SHORTSHEET',
        'SIERRAMONTANA',
        'SILVERZEPHYR',
        'SKYWRITER',
        'SLICKERVICAR',
        'SNEAKERNET',
        'SOLIS',
        'SOMBERKNAVE',
        'SOUFFLETROUGH',
        'SPARROW',
        'SPECULATION',
        'SPHINX',
        'SPINNERET',
        'SPOKE',
        'SPOTBEAM',
        'SSG',
        'SSO',
        'STEELFLAUTA',
        'STEELKNIGHT',
        'STELLARWIND',
        'STONE',
        'STORMBREW',
        'STRAITBAZZARE',
        'STRAITBIZARRE',
        'STRIKEZONE',
        'STRONGMITE',
        'STUCCOMONTANA',
        'STUMPCURSOR',
        'STUXNET',
        'SURLYSPAWN',
        'SURPLUSHANGAR',
        'SURREY',
        'SUTURESAILOR',
        'SWAP',
        'TALENT',
        'TALK',
        'TAO',
        'TAPERLAY',
        'TAROTCARD',
        'TAWDRYYARD',
        'TEMPEST',
        'THINTREAD',
        'TLN',
        'Tool',
        'TOTECHASER',
        'TOTEGHOSTLY',
        'TRAFFICTHIEF',
        'TRAILBLAZER',
        'TREASUREMAP',
        'TRIBUTARY',
        'TRINE',
        'TRINITY',
        'TUMULT',
        'TUNING',
        'TUNINGFORK',
        'TURBINE',
        'TURBOPANDA',
        'TURBULANCE',
        'TURBULENCE',
        'TURMOIL',
        'TUSKATTIRE',
        'TUTELAGE',
        'TWISTEDKILT',
        'TYPHON',
        'UMBRA',
        'UNIFORM',
        'UNITEDRAKE',
        'UPSTREAM',
        'VAGRANT',
        'VALIDATOR',
        'VIEWPLATE',
        'WAGONBED',
        'WATERWITCH',
        'WEALTHYCLUSTER',
        'WEBCANDID',
        'WHITEBOX',
        'WISTFULTOLL',
        'WRANGLER',
        'XCONCORD',
        'XKEYSCORE',
        'YACHTSHOP',
        'YELLOWPIN',
        'ZESTYLEAK',
        'Arthur',
        'Lancelot',
        'Tristan',
        'Gawain',
        'Bors',
        'Bedivere',
        'Galahad',
        'Gaheris',
        'Gareth',
        'Kay',
        'Percivale',
        'Agravaine',
        'Judas',
        'Zeus',
        'Hera',
        'Poseidon',
        'Demeter',
        'Hades',
        'Hestia',
        'Apollo',
        'Artemis',
        'Ares',
        'Aphrodite',
        'Athena',
        'Hephaestus',
        'Hermes',
        'Dionysus',
        'Phobos',
        'Deimos',
        'Eros',
        'Eris',
        'Nike',
        'Eos',
        'Helios',
        'Phosphoros',
        'Iris',
        'Hebe',
        'Charon',
        'Triton',
        'Persephone',
        'Hecate',
        'Pan',
        'Clotho',
        'Lachesis',
        'Atropos',
        'Atlas',
        'Gaia',
        'Coeus',
        'Crius',
        'Cronos',
        'Epimetheus',
        'Hyperion',
        'Iapetus',
        'Leto',
        'Mnemosyne',
        'Oceanus',
        'Uranus',
        'Phoebe',
        'Prometheus',
        'Rhea',
        'Tethys',
        'Themis',
        'Sparta',
        'Snowball',
        'Chicago',
        'Detroit',
        'Nashville',
        'Memphis',
        'Millennium',
        'Razzle',
        'Daytona',
        'Cairo',
        'Wolfpack',
        'Janus',
        'Impala',
        'Neptune',
        'Triton',
        'Odyssey',
        'Whistler',
        'Mantis',
        'Freestyle',
        'Harmony',
        'Symphony',
        'Emerald',
        'Diamond',
        'Springboard',
        'Lonestar',
        'Whistler',
        'Bobcat',
        'Longhorn',
        'Blackcomb',
        'Quattro',
        'Vail',
        'Longhorn',
        'Cougar',
        'Centro',
        'Fiji',
        'Aurora',
        'Quebec',
        'Blue',
        'Threshold',
        'Redstone',
        'Santorini',
        'Titanium',
        'Vanadium',
        'Vibranium',
        'Manganese',
        'Iron',
        'Cobalt',
        'Sun Valley',
        'Nickel',
        'Copper',
        'Pegasus',
        'Cedar',
        'Talisker',
        'Jameson',
        'McKendric',
        'Macallan',
        'Yamazaki',
        'Chelan',
        'Rapier',
        'Merlin',
        'Ozone',
        'Magneto',
        'Crossbow',
        'Titanium',
        'Maldives',
        'Photon',
        'Blue',
        'Threshold',
        'INTERLOPER',
        'Boss',
        'Babou',
    ];

    public getRandomCodename(): string {
        const rnd = Randomizer.getRandomNumber(0, this._codeNames.length - 1);
        return this._codeNames[rnd].toUpperCase();
    }
}
